/* Link buttons */
.button {
    text-decoration: none;
    font-size: 1rem;
    color: var(--primary-color);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
}

.button:hover {
    background: var(--cerise-red-950);
}

/* Keyboard keys */
.kb-set {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
    gap: 0.5rem;
}

.kb {
    display: inline-block;
    padding: 0.25rem;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 0.25rem;
    border: 0.5px solid var(--slate-700);
    background-color: var(--background-dark);
    box-shadow: 0px 2px 0px 0px var(--slate-800);
    color: var(--primary-color);
    text-align: center;
    vertical-align: middle;
    font-size: 0.75rem;
    font-weight: 600;
}

.kb.long {
    padding: 0.25rem 0.5rem;
}

.kb.spacebar {
    padding: 0.25rem 2rem;
}

.kb_description {
    margin-left: 0.5rem;
}

/* Notifications */
.notification {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    background: transparent;
    padding: 0.857rem;
    margin-bottom: 0.5rem;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--slate-800);
    border-radius: 0.25rem;
    color: var(--slate-400);
    font-size: 0.85rem;
}

/* Dropdown */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: var(--background-dark);
    border: 1px solid var(--slate-800);
    border-radius: 0.25rem;
    padding: 0.5rem 0;
    z-index: 1000;
    min-width: 150px;
}

.dropdown-content div {
    display: block;
    padding: 0.5rem 1rem;
    color: var(--text-color);
    cursor: pointer;
}

.dropdown-content div:hover {
    background-color: var(--slate-800);
}