@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
:root {
  --slate-50: #f8fafc;
  --slate-100: #f1f5f9;
  --slate-200: #e2e8f0;
  --slate-300: #cbd5e1;
  --slate-400: #94a3b8;
  --slate-500: #64748b;
  --slate-600: #475569;
  --slate-700: #334155;
  --slate-800: #1e293b;
  --slate-900: #0f172a;
  --slate-950: #020617;

  --cerise-red-50: #fef2f4;
  --cerise-red-100: #fde6e9;
  --cerise-red-200: #fbd0d9;
  --cerise-red-300: #f7aab9;
  --cerise-red-400: #f27a93;
  --cerise-red-500: #e63f66;
  --cerise-red-600: #d42a5b;
  --cerise-red-700: #b21e4b;
  --cerise-red-800: #951c45;
  --cerise-red-900: #801b40;
  --cerise-red-950: #470a1f;

  --primary-color: var(--cerise-red-400);
  --background-dark: var(--slate-900);
  --background-black: var(--slate-950);
  --text-color: var(--slate-300);
  --border-color: var(--slate-600);
  
  font-size: 14px;
}

body {
  color: var(--text-color);
  background: radial-gradient(50% 50% at 50% 50%, #290F16 0%, #120217 75%, #020617 100%);
  margin: 0;
  font-family: 'Helvetica Neue', 'Lato', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:focus {
  outline: none;
  border: 0 !important;
  box-shadow: 0 0 4px #3B82F6;
}

textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--slate-600, #475569);
}
textarea::-moz-placeholder { /* Firefox 19+ */
  color: var(--slate-600, #475569);
}
textarea:-ms-input-placeholder { /* IE 10+ */
  color: var(--slate-600, #475569);
}
textarea:-moz-placeholder { /* Firefox 18- */
  color: var(--slate-600, #475569);
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: var(--slate-600, #475569);
}
::-moz-placeholder { /* Firefox 19+ */
    color: var(--slate-600, #475569);
}
:-ms-input-placeholder { /* IE 10+ */
    color: var(--slate-600, #475569);
}
:-moz-placeholder { /* Firefox 18- */
    color: var(--slate-600, #475569);
}

html,
body,
#root {
    height: 100vh;
}

:focus {
    box-shadow: none;
}

.container {
    color: var(--cerise-red-50);
    font-family: 'Poppins', 'Helvetica Neue', sans-serif !important;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 38rem;
}

.j-logotype {
    max-width: 120px;
    width: 8.5rem;
    height: auto;
}

.hero {
    line-height: 120%;
    font-size: 2rem;
}

.accent {
    color: var(--cerise-red-700);
}

.input-container {
    display: flex;
    padding: 1rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    border-radius: 0.375rem;
    border: 1px solid var(--carnation-950, #4B0619);
    background: var(--slate-950, #020617);
    box-shadow: 0px 1px 0px 0px #0F172A;
}

.input-field {
    width: 100%;
    border: none;
    background: transparent;
    color: var(--text-color, #cbd5e1);
}

.kb {
    height: auto !important;
}

.mb-1 {
    margin-bottom: .5rem;
}

.mb-2 {
    margin-bottom: 2.25rem;
}
.note {
    background: var(--background-dark);
    padding: 0.857rem;
    margin-bottom: 0.5rem;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid var(--slate-800);
    border-radius: 0.25rem;
}

.note:hover {
    cursor: pointer;
}

.note .actions {
    color: var(--slate-600);
    display: flex;
    padding-top: 0.857rem;
    flex-direction: row;
    justify-content: space-between;
}

.time_code {
    font-size: 0.75rem;
    color: var(--primary-color);
    margin-bottom: 0.25rem;
}
#interface {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
}

#left-side {
    object-fit: none;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
}

#right-side {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 300px;
    overflow-y: auto;
    padding: 0.285rem;
    box-sizing: border-box;
    background: var(--background-black);
}

#right-side::-webkit-scrollbar {
    background-color: transparent;
}

#header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

#header .actions {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
}

#right-side > div {
    margin-bottom: 0.285rem;
}

#adsense {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#video_player {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
}

@media only screen and (max-width: 896px) {
    #interface {
        flex-direction: column;
    }

    #adsense {
        display: none;
    }

    #left-side {
        height: auto;
        aspect-ratio: 16/9;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
    }
}

/* Link buttons */
.button {
    text-decoration: none;
    font-size: 1rem;
    color: var(--primary-color);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
}

.button:hover {
    background: var(--cerise-red-950);
}

/* Keyboard keys */
.kb-set {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.kb {
    display: inline-block;
    padding: 0.25rem;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 0.25rem;
    border: 0.5px solid var(--slate-700);
    background-color: var(--background-dark);
    box-shadow: 0px 2px 0px 0px var(--slate-800);
    color: var(--primary-color);
    text-align: center;
    vertical-align: middle;
    font-size: 0.75rem;
    font-weight: 600;
}

.kb.long {
    padding: 0.25rem 0.5rem;
}

.kb.spacebar {
    padding: 0.25rem 2rem;
}

.kb_description {
    margin-left: 0.5rem;
}

/* Notifications */
.notification {
    display: flex;
    flex-direction: row;
    grid-gap: 0.75rem;
    gap: 0.75rem;
    background: transparent;
    padding: 0.857rem;
    margin-bottom: 0.5rem;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--slate-800);
    border-radius: 0.25rem;
    color: var(--slate-400);
    font-size: 0.85rem;
}

/* Dropdown */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: var(--background-dark);
    border: 1px solid var(--slate-800);
    border-radius: 0.25rem;
    padding: 0.5rem 0;
    z-index: 1000;
    min-width: 150px;
}

.dropdown-content div {
    display: block;
    padding: 0.5rem 1rem;
    color: var(--text-color);
    cursor: pointer;
}

.dropdown-content div:hover {
    background-color: var(--slate-800);
}
