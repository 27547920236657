:root {
  --slate-50: #f8fafc;
  --slate-100: #f1f5f9;
  --slate-200: #e2e8f0;
  --slate-300: #cbd5e1;
  --slate-400: #94a3b8;
  --slate-500: #64748b;
  --slate-600: #475569;
  --slate-700: #334155;
  --slate-800: #1e293b;
  --slate-900: #0f172a;
  --slate-950: #020617;

  --cerise-red-50: #fef2f4;
  --cerise-red-100: #fde6e9;
  --cerise-red-200: #fbd0d9;
  --cerise-red-300: #f7aab9;
  --cerise-red-400: #f27a93;
  --cerise-red-500: #e63f66;
  --cerise-red-600: #d42a5b;
  --cerise-red-700: #b21e4b;
  --cerise-red-800: #951c45;
  --cerise-red-900: #801b40;
  --cerise-red-950: #470a1f;

  --primary-color: var(--cerise-red-400);
  --background-dark: var(--slate-900);
  --background-black: var(--slate-950);
  --text-color: var(--slate-300);
  --border-color: var(--slate-600);
  
  font-size: 14px;
}

body {
  color: var(--text-color);
  background: radial-gradient(50% 50% at 50% 50%, #290F16 0%, #120217 75%, #020617 100%);
  margin: 0;
  font-family: 'Helvetica Neue', 'Lato', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:focus {
  outline: none;
  border: 0 !important;
  box-shadow: 0 0 4px #3B82F6;
}

textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--slate-600, #475569);
}
textarea::-moz-placeholder { /* Firefox 19+ */
  color: var(--slate-600, #475569);
}
textarea:-ms-input-placeholder { /* IE 10+ */
  color: var(--slate-600, #475569);
}
textarea:-moz-placeholder { /* Firefox 18- */
  color: var(--slate-600, #475569);
}