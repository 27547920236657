@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: var(--slate-600, #475569);
}
::-moz-placeholder { /* Firefox 19+ */
    color: var(--slate-600, #475569);
}
:-ms-input-placeholder { /* IE 10+ */
    color: var(--slate-600, #475569);
}
:-moz-placeholder { /* Firefox 18- */
    color: var(--slate-600, #475569);
}

html,
body,
#root {
    height: 100vh;
}

:focus {
    box-shadow: none;
}

.container {
    color: var(--cerise-red-50);
    font-family: 'Poppins', 'Helvetica Neue', sans-serif !important;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 38rem;
}

.j-logotype {
    max-width: 120px;
    width: 8.5rem;
    height: auto;
}

.hero {
    line-height: 120%;
    font-size: 2rem;
}

.accent {
    color: var(--cerise-red-700);
}

.input-container {
    display: flex;
    padding: 1rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    border-radius: 0.375rem;
    border: 1px solid var(--carnation-950, #4B0619);
    background: var(--slate-950, #020617);
    box-shadow: 0px 1px 0px 0px #0F172A;
}

.input-field {
    width: 100%;
    border: none;
    background: transparent;
    color: var(--text-color, #cbd5e1);
}

.kb {
    height: auto !important;
}

.mb-1 {
    margin-bottom: .5rem;
}

.mb-2 {
    margin-bottom: 2.25rem;
}