#interface {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
}

#left-side {
    object-fit: none;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
}

#right-side {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 300px;
    overflow-y: auto;
    padding: 0.285rem;
    box-sizing: border-box;
    background: var(--background-black);
}

#right-side::-webkit-scrollbar {
    background-color: transparent;
}

#header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

#header .actions {
    display: flex;
    gap: 8px;
}

#right-side > div {
    margin-bottom: 0.285rem;
}

#adsense {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#video_player {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
}

@media only screen and (max-width: 896px) {
    #interface {
        flex-direction: column;
    }

    #adsense {
        display: none;
    }

    #left-side {
        height: auto;
        aspect-ratio: 16/9;
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
    }
}
