.note {
    background: var(--background-dark);
    padding: 0.857rem;
    margin-bottom: 0.5rem;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid var(--slate-800);
    border-radius: 0.25rem;
}

.note:hover {
    cursor: pointer;
}

.note .actions {
    color: var(--slate-600);
    display: flex;
    padding-top: 0.857rem;
    flex-direction: row;
    justify-content: space-between;
}

.time_code {
    font-size: 0.75rem;
    color: var(--primary-color);
    margin-bottom: 0.25rem;
}